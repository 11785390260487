@import 'react-toastify/dist/ReactToastify.css';
@import 'react-toggle/style.css';

.ReactModal__Overlay {
  background-color: #0201018a !important;
  z-index: 999;
}
.ReactModal__Content {
  inset: unset !important;
  min-height: 100px !important;
  width: 100%;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  max-height: 80vh !important;
  border: 1px solid black !important;
  background: white !important;
  border-radius: 10px !important;
}

#firebaseui_container {
  margin: 20px 0;
}
.firebaseui-card-actions {
  box-sizing: border-box !important;
}

.chrome-picker,
.sketch-picker,
.twitter-picker,
.block-picker,
.circle-picker,
.compact-picker,
.github-picker,
.hue-picker,
.material-picker,
.photoshop-picker,
.slider-picker,
.swatches-picker,
.trello-picker {
  box-shadow: none !important;
}

.default-slider {
  width: 100%;
  height: 25px;

  & .thumb {
    height: 25px;
    line-height: 25px;
    width: 25px;
    text-align: center;
    background-color: white;
    color: #fff;
    border-radius: 50%;
    border: 1px solid var(--theme-main);
    cursor: grab;
  }

  & .track {
    top: 50%;
    background: var(--theme-background);
    border-radius: 999px;
    height: 3px;
    transform: translateY(-50%);
  }
}
