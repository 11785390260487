* {
  font-family: 'Roboto-Regular';
  box-sizing: border-box;
}

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  float: none;
}

#northern-devs-link {
  position: absolute;
  top: -100000px;
  left: -1000000px;
  opacity: 0;
}

html,
body {
  background-color: white;
}

.tooltip-container:hover .colour-tooltip {
  display: block;
}
.colour-tooltip {
  position: absolute;
  padding: 5px 20px;
  bottom: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  z-index: 100;
  border-radius: 5px;
  text-align: center;
  display: none;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border: 10px solid transparent;
    border-top-color: black;
  }
}
